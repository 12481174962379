import { call, put, takeLatest } from "@redux-saga/core/effects";

import {
  TICKET_STATUS,
  TICKET_STATUS_SUCCESS,
  TICKET_STATUS_FAILED,

  TICKET_REPORT,
  TICKET_REPORT_SUCCESS,
  TICKET_REPORT_FAILED

} from "../actions/ticketAction";

import { ticketService } from "../../services/service.index";


export function* watchGetTicketStatus() {
  yield takeLatest(TICKET_STATUS, getTicketStatus);
}

export function* watchTicketReportGet() {
  yield takeLatest(TICKET_REPORT, getTicketReportSaga);
}

export function* getTicketStatus(action) {
  try {
    const response = yield call(
      ticketService.ticketGetallStatus,
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: TICKET_STATUS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: TICKET_STATUS_FAILED,
      });
    }
  } catch (e) {
    // console.log("error", e);
    yield put({
      type: TICKET_STATUS_FAILED,
    });
  }
}
export function* getTicketReportSaga(action) {
  try {
    const response = yield call(
      ticketService.ticketReport,
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: TICKET_REPORT_SUCCESS,
        payload: response,
      });
    } else {
      // console.log("getInspectionSaga Error response", response)
      yield put({
        type: TICKET_REPORT_FAILED,
      });
    }
  } catch (e) {
    // console.log("getInspectionSaga Error response", e)
    yield put({
      type: TICKET_REPORT_FAILED,
    });
  }
}