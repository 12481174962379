import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Commonheader from "../../layouts/common/commonHeader";
import UploadInspection from "./inspection/uploadInspection";
import ScheduleUploadheader from "../../layouts/schedule/scheUploadHeader";
// import UploadMaintenance from "./maintenance/uploadMaintenance";
import Loader from "../../components/loader/loader";
import { useDispatch, useSelector } from "react-redux";
import PlannedActivity from "./maintenance/reusablePlannedActivity";
import Maintenancenew from "./maintenance/maintenance";

function ChooseType() {
  const [key, setKey] = useState("inspection");
  const isLoadingInsp = useSelector((state) => state.inspection.isLoading);
  const isLoadingMaint = useSelector((state) => state.maintenance.isLoading);


  return (
    <div>
      <Commonheader />
      {/* {isLoadingInsp && <Loader />} */}
      {isLoadingMaint && <Loader />}

      <div className="pt-5 mx-2">
        <Tabs
          id="fill-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3 activeTab"
          // fill
        >
          <Tab eventKey="inspection" title="Inspection">
            <UploadInspection />
          </Tab>
          {/* <Tab eventKey="planned_maintenance" title="Planned Maintenance">
            <UploadMaintenance />
          </Tab> */}
          <Tab eventKey="activity" title="Planned Maintenance">
            <Maintenancenew />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default ChooseType;
