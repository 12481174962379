/* Feature - Maintenance List to View

Created by - Mona R
Created at - 21st Aug 2024

*/

//importing dependencies and packages
import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faClose,
  faAngleRight,
  faTrash,
  faPenToSquare,
  faStopwatch,
  faUserPlus,
  faX,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import Loader from "../../../components/loader/loader";
import MainAssign from "./mainAssign";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation } from 'react-router-dom';
import moment from "moment";


function MaintenanceList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //useState
  const [modalShow, setModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [ID, setID] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [key, setKey] = useState("asset");
  const location = useLocation();

  const isLoading = useSelector((state) => state.maintenance.isLoading);
  const maintenanceData = useSelector(
    (state) => state.maintenance.maintenanceData
  );
  // console.log(maintenanceData);
  const role = useSelector((state) => state.auth.role);
  const accessMain = useSelector((state) => state.access.assetMaintenance);
  const accessAssign = useSelector(
    (state) => state.access.assetMaintenanceAssign
  );
  const userid = useSelector((state) => state.auth.userid);

  const handleDelete = (_id) => {
    dispatch({
      type: "MAINT_DELETE_LIST",
      payload: [key, currentPage, itemsPerPage, searchInput, _id],      
    });
  };

  // Calculate the total number of pages
  let totalPages = 0;
  var maintList = [];
  if (maintenanceData.result !== undefined) {
    maintList = maintenanceData.result;
  }
  if (maintenanceData?.total_count !== undefined) {
    totalPages = Math.ceil(maintenanceData.total_count / itemsPerPage);
  }

  // Function to handle next button
  const handleNext = () => {
    setCurrentPage((currentPage) => currentPage + 1);
    dispatch({
      type: "MAINT_GET_LIST",
      payload: [currentPage, itemsPerPage],
    });
  };

  // Function to handle previous button
  const handlePrev = () => {
    setCurrentPage((currentPage) => currentPage - 1);
    dispatch({
      type: "MAINT_GET_LIST",
      payload: [currentPage, itemsPerPage],
    });
  };

  //Function to handle FirstPage Button
  const handleFirstPage = () => {
    setCurrentPage(1);
    dispatch({
      type: "MAINT_GET_LIST",
      payload: [currentPage, itemsPerPage],
    });
  };

  //Function to handle LastPage Button
  const handleLastpage = () => {
    setCurrentPage(totalPages);
    dispatch({
      type: "MAINT_GET_LIST",
      payload: [currentPage, itemsPerPage],
    });
  };

  // Function to handle items per page change
  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

   // Handle Tab change key
   const handleKey = (k) => {
    setKey(k)
    setCurrentPage(1);
  }

  //Function to handel clear
  const handleClearButton = () => {
    setSearchInput("");
  };


  // dispatch to stop the schedule
  const handleStop = (_id) => {
    // console.log('_id', _id);
    let maintenanceAssignData = {
      // frequency: {
      //   frequency_start: "",
      // },
      // assignee: "",
      // schedule_status: "yet_to_be_assigned",
      modified_by: userid,
    };
    // console.log(inspectionAssignData);
    const data = [key, currentPage, itemsPerPage, searchInput]
    dispatch({
      type: "MAINTENANCE_STOP",
      payload: [maintenanceAssignData, _id, data],
    });
  };

  useEffect(() => {
    const roleCode = role[0];
    // console.log(roleCode);
    if ((accessMain === null || accessAssign === null) && roleCode !== null) {
      dispatch({
        type: "ACCESS_GET_LIST",
        payload: roleCode,
      });
    }
  }, [role, accessMain, accessAssign]);

  //useEffect
  useEffect(() => {
    if (currentPage && currentPage > 0 || searchInput.length > 0) {
      dispatch({
        type: "MAINT_GET_LIST",
        payload: [key, currentPage, itemsPerPage, searchInput],
      });
    }
  }, [currentPage, itemsPerPage, key, searchInput]);

  useEffect(() => {
    if (location.state === null) {
      setKey('asset')
    } else if (location.state.data !== undefined && location.state.data !== null) {
      // console.log(location.state.data);
      setKey(location.state.data)
    }
  }, [])

  return (
    <div>
      {isLoading && <Loader />} {/* to Show the Loading data */}
      <div className="pt-5">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => handleKey(k)}
          className="m-3 headings"
        >
          <Tab eventKey="asset" title="Asset"></Tab>&nbsp;
          <Tab eventKey="device" title="Device"></Tab>&nbsp;
          <Tab eventKey="facility" title="Facility"></Tab>
        </Tabs>
      </div>
      <div className="col-md-12">
        <div
          // className="listHeadings"
          style={{
            fontSize: "20px",
            paddingLeft: "100px",
            paddingTop: "5px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {key.charAt(0).toUpperCase() + key.slice(1)} - Maintenance List
          <div style={{ float: "right" }}>
            <form
              className="mb-4"
            >
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={handleSearchInputChange}
                style={{
                  border: "1px solid steelblue",
                  fontSize: "15px",
                  padding: "5px",
                }}
              />
              {/* {console.log(searchInput)} */}
              <span
                type="reset"
                onClick={handleClearButton}
                style={{ paddingLeft: "8px", marginRight: "25px" }}
              >
                <FontAwesomeIcon icon={faClose} size="lg" className="pt-3" />
              </span>
            </form>
          </div>
          <div>Maintenance Edit, Delete & Assign</div>
        </div>
        {/* Table to view  list */}
        <div className="mb-6">
          <table className="table-bordered" width="100%">
            <thead>
              {/* Table heading to display list of Site */}
              <tr
                style={{
                  textAlign: "center",
                  backgroundColor: "steelblue",
                  padding: "8px",
                  color: "#fff",
                }}
              >
                <th style={{ padding: "8px" }}>S. No</th>
                <th scope="col">
                  {key.charAt(0).toUpperCase() + key.slice(1)} ID
                </th>
                <th
                  scope="col"
                  style={{ textAlign: "left", paddingLeft: "5px" }}
                >
                  Maintenance Name
                </th>
                <th scope="col">Priority</th>
                <th scope="col">Status</th>
                {/* <th scope="col">Assignee type</th> */}
                <th scope="col">Assigned</th>
                <th scope="col">Start Date & Time</th>
                <th
                  scope="col"
                  colSpan="4"
                  className="actionColumn"
                  style={{ textAlign: "center" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            {maintList !== null && maintList.length > 0 ? (
              maintList.map((eachData, index) => {
                // console.log(eachData);
                return (
                  <tbody key={eachData._id} style={{ padding: "15px" }}>
                    <tr style={{ padding: "20px" }}>
                      <td style={{ textAlign: "center", padding: "8px" }}>
                        {(currentPage - 1) * itemsPerPage + (index + 1)}
                      </td>
                      <td style={{ textAlign: "center", paddingLeft: "5px", paddingRight: "5px" }}>
                        {eachData.parent_id}
                      </td>
                      <td style={{ textAlign: "left", paddingLeft: "5px" }}>
                        {eachData.schedule_name}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {eachData.priority.charAt(0).toUpperCase() +
                          eachData.priority.slice(1)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {eachData.schedule_status === "yet_to_be_assigned"
                          ? "Yet to be assigned"
                          : `${eachData.schedule_status}` ||
                            eachData.maintenace_status === "assigned"
                          ? "Assigned"
                          : `${eachData.maintenace_status}`}
                      </td>
                      {/* <td style={{ textAlign: "center" }}>
                        {eachData.assignee_type
                          ? `${(eachData.assignee_type).charAt(0).toUpperCase()+ (eachData.assignee_type).slice(1)}`
                          : "-"}
                      </td> */}
                      <td style={{ textAlign: "center" , maxWidth: '20rem'}}>
                        {eachData.assignee ? 
                        ((eachData.assignee).join(', ') ) 
                        : "-"}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {eachData.frequency_start ? (
                          // <input
                          //   disabled
                          //   style={{
                          //     border: "none",
                          //     paddingLeft: "8px",
                          //   }}
                          //   type="text"
                          //   // defaultValue={(new Date(eachData.frequency_start)).toLocaleString()}
                          //   defaultValue={new Intl.DateTimeFormat("en-US", {
                          //     year: "numeric",
                          //     month: "short",
                          //     day: "numeric",
                          //     hour: "numeric",
                          //     minute: "numeric",
                          //     second: "numeric",
                          //     timeZone: "UTC",
                          //   }).format(new Date(eachData.frequency_start))}
                          // />
                          moment(eachData.frequency_start).local().format("MMMM Do YYYY | hh:mm A")
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            navigate(`/maintenance/view/${eachData._id}`);
                          }}
                          style={{
                            color: "#2D7DC1",
                            border: "none",
                            background: "none",
                            display: "block",
                            margin: "auto",
                            alignItems: "center",
                          }}
                          data-tip
                          data-for="viewTip"
                        >
                          <FontAwesomeIcon icon={faEye} size="lg" />
                          <ReactTooltip
                            id="viewTip"
                            place="bottom"
                            effect="solid"
                          >
                            View Maintenance
                          </ReactTooltip>
                        </button>
                      </td>
                      {accessAssign !== null &&
                      accessAssign.u &&
                      accessMain !== null &&
                      accessMain.u ? (
                        <td style={{ textAlign: "center" }}>
                          {eachData.assignee ? (
                            <button
                              disabled
                              style={{
                                color: "#6fa8dc",
                                border: "none",
                                background: "none",
                              }}
                              data-tip
                              data-for="updateTip"
                            >
                              <FontAwesomeIcon icon={faPenToSquare} size="lg" />
                              <ReactTooltip
                                id="updateTip"
                                place="bottom"
                                effect="solid"
                              >
                                Edit
                              </ReactTooltip>
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                navigate(
                                  `/maintainance/update/${eachData._id}?type=${key}`
                                );
                              }}
                              style={{
                                color: "#2D7DC1",
                                border: "none",
                                background: "none",
                              }}
                              data-tip
                              data-for="updateTip"
                            >
                              <FontAwesomeIcon icon={faPenToSquare} size="lg" />
                              <ReactTooltip
                                id="updateTip"
                                place="bottom"
                                effect="solid"
                              >
                                Edit
                              </ReactTooltip>
                            </button>
                          )}
                        </td>
                      ) : null}
                      {accessAssign !== null && accessAssign.c ? (
                        <td style={{ textAlign: "center" }}>
                          {eachData.schedule_status ===
                          "yet_to_be_assigned" ? (
                            <>
                              <button
                                onClick={() => {
                                  setModalShow(true);
                                  setID(eachData._id);
                                }}
                                style={{
                                  color: "#2D7DC1",
                                  border: "none",
                                  background: "none",
                                }}
                                data-tip
                                data-for="assignTip1"
                              >
                                <FontAwesomeIcon icon={faUserPlus} size="lg" />

                              </button>
                              <ReactTooltip
                                className="solid"
                                id="assignTip1"
                                place="bottom"
                                effect="solid"
                              >
                                Assign User
                              </ReactTooltip></>
                          ) : (

                            <>
                              <button
                                onClick={() => {
                                  window.confirm(
                                    "Are you sure you wish to stop this item?"
                                  ) && handleStop(eachData._id);
                                }}
                                style={{
                                  color: "#2D7DC1",
                                  border: "none",
                                  background: "none",
                                }}
                                data-tip
                                data-for="assignTip2"
                              >
                                <FontAwesomeIcon icon={faX} size="lg" />

                              </button>
                              <ReactTooltip
                                className="solid"
                                id="assignTip2"
                                place="bottom"
                                effect="solid"
                              >
                                Stop
                              </ReactTooltip></>
                          )}
                        </td>
                      ) : null}
                      {accessMain !== null && accessMain.d ? (
                        <td style={{ textAlign: "center" }}>
                          {eachData.assignee ? (
                            <button
                              disabled
                              style={{
                                color: "#6fa8dc",
                                border: "none",
                                background: "none",
                              }}
                              data-tip
                              data-for="deleteTip"
                            >
                              <FontAwesomeIcon icon={faTrash} size="lg" />
                              <ReactTooltip
                                id="deleteTip"
                                place="bottom"
                                effect="solid"
                              >
                                Delete
                              </ReactTooltip>
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                window.confirm(
                                  "Are you sure you want to delete this item?"
                                ) && handleDelete(eachData._id);
                              }}
                              style={{
                                color: "#2D7DC1",
                                border: "none",
                                background: "none",
                              }}
                              data-tip
                              data-for="deleteTip"
                            >
                              <FontAwesomeIcon icon={faTrash} size="lg" />
                              <ReactTooltip
                                id="deleteTip"
                                place="bottom"
                                effect="solid"
                              >
                                Delete
                              </ReactTooltip>
                            </button>
                          )}
                        </td>
                      ) : null}
                    </tr>
                  </tbody>
                );
              })
            ) : (
              <tbody>
                <tr style={{ textAlign: "center", height: "75px" }}>
                  <td colSpan="8">No Data Available</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>

        {/* pagination */}
        {maintList !== null && maintList.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "30px",
              padding: "10px",
            }}
          >
            <div className="mb-6">
              <label>Items per page:</label> &nbsp;
              <select
                onChange={(e) => handleItemsPerPageChange(e.target.value)}
                style={{ borderRadius: "8px", cursor: "pointer" }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
                {/* <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={300}>300</option>
              <option value={400}>400</option>
              <option value={500}>500</option> */}
              </select>
            </div>

            {/* //pagination */}
            <div style={{ paddingRight: "25px" }} className="pagination">
              <button
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "#fff",
                }}
                disabled={currentPage === 1}
                onClick={handleFirstPage}
              >
                {currentPage === 1 ? (
                  <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
                ) : (<FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />)}
              </button>
              <button
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "#fff",
                }}
                disabled={currentPage === 1}
                onClick={handlePrev}
              >
                {/* Prev */}
                {currentPage === 1 ? (
                  <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
                ) : (<FontAwesomeIcon icon={faAngleLeft} color="steelblue" />)}
              </button>
              <span style={{ padding: "10px" }}>
                Showing {currentPage} of {totalPages} pages
              </span>
              {(totalPages === 0 || currentPage === totalPages) ? (
                <>
                  <button
                    style={{
                      padding: "5px",
                      cursor: "pointer",
                      backgroundColor: "#fff",
                      border: "none",
                    }}
                    disabled={true}
                  >
                    <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
                  </button>
                  <button
                    style={{
                      padding: "5px",
                      cursor: "pointer",
                      backgroundColor: "#fff",
                      border: "none",
                    }}
                    disabled={true}
                  >
                    <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
                  </button>
                </>
              ) : <>
                <button
                  style={{
                    padding: "5px",
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    border: "none",
                  }}
                  disabled={currentPage === totalPages}
                  onClick={handleNext}
                >
                  <FontAwesomeIcon icon={faAngleRight} color="steelblue" />
                </button>
                <button
                  style={{
                    padding: "5px",
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    border: "none",
                  }}
                  disabled={currentPage === totalPages}
                  onClick={handleLastpage}
                >
                  <FontAwesomeIcon icon={faAngleDoubleRight} color="steelblue" />
                </button>
              </>}

            </div>
          </div>
        ) : null}
      </div>
      {modalShow && (
        <MainAssign
          show={modalShow}
          onHide={() => setModalShow(false)}
          id={ID}
          pagenodata={[key, currentPage, itemsPerPage, searchInput]}
        />
      )}
    </div>
  );
}

export default MaintenanceList;
