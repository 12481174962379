import axios from 'axios';

export const getIpAddress = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json&ipversion=4');
    console.log(response.data);
    return response.data.ip;
  } catch (error) {
    console.error('Error fetching IPv4 address:', error);
  }
};
