import React from "react";
import PlannedActivity from "./reusablePlannedActivity";
import { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  MAINT_ADD,
  MAINTENANCE_UPDATE,
} from "../../../redux/actions/maintAction";

function Maintenancenew() {
  // Importing the useDispatch hook from react-redux to dispatch actions to the Redux store
  const dispatch = useDispatch();
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  // Extracting the 'id' parameter from the URL using the useParams hook from react-router-dom
  const { id } = useParams();

  const maintGetSingle = useSelector(
    (state) => state.maintenance.maintGetSingle
  );
  const isSubmitted = useSelector((state) => state.maintenance.isSubmitted);
  const sopData = useSelector((state) => state.maintenance.sop);
  const [data, setData] = useState({
    activity_name: {
      lable: "Planned Maintenance Activity Name",
      placeholder: "Maintenance Activity Name",
      type: "text",
    },
    type: {
      lable: "Planned Maintenance Type",
      placeholder: "",
      type: "select",
      options: [
        { val: "autonomous", lable: "Autonomous" },
        { val: "preventive", lable: "Preventive" },
        { val: "predictive", lable: "Predictive" },
      ],
    },
    priority: {
      lable: "Priority",
      placeholder: "",
      type: "select",
      options: [
        { val: "high", lable: "High" },
        { val: "medium", lable: "Medium" },
        { val: "low", lable: "Low" },
      ],
    },
    based_on: {
      lable: "Based On",
      placeholder: "",
      type: "select",
      options: [{ val: "calendar", lable: "Calendar" }],
    },
    managed_by: {
      lable: "Managed by",
      placeholder: "",
      type: "select",
      options: [
        { val: "inhouse", lable: "In house" },
        { val: "external", lable: "External" },
      ],
    },
    main_duration: {
      lable: "Maintenance Duration(Mins)",
      placeholder: "Minutes",
      type: "number",
    },
    shut_down_duration: {
      lable: "Shut Down Duration(Mins)",
      placeholder: "Minutes",
      type: "number",
    },
    man_power: {
      lable: "Total Man Power required",
      placeholder: "Total man power",
      type: "number",
    },
    man_hour: {
      lable: "Total Man Hour required",
      placeholder: "Total man hour",
      type: "number",
    },
  });

  // console.log(sopData, 'sopdata');
  

  const [calendarFreqncy, setCalendarFreqncy] = useState({
    type: "", //"months",
    type_value: "",
    end_type: "", //'no_end'
    occur_value: "",
    date_value: "",
  });

  const [operation, setOperation] = useState("upload"); // to handle title and other CRUD Operation (upload, update & view)

  const submitData_upload = (maintenanceData) => {
    // console.log(data);
    dispatch({
      type: MAINT_ADD,
      payload: [queryParams.get("type"), maintenanceData],
    });
  };

  const submitData_update = (updateMaintData) => {
    // console.log(updateMaintData);
    dispatch({
      type: MAINTENANCE_UPDATE,
      payload: [updateMaintData, id],
    });
  };

  useState(() => {}, [data, calendarFreqncy]);

  useState(() => {
    const currentURL = window.location.href;
    // console.log(currentURL);
    if (currentURL.includes("maintenance")) {
      setOperation("upload");
    }
    if (currentURL.includes("view")) {
      setOperation("view");
    }
    if (currentURL.includes("update")) {
      setOperation("update");
    }
  }, [id]);

  //useEffects
  useEffect(() => {
    //fetch inspection Mongo ID
    if (id !== null && (operation === "view" || operation === "update")) {
      dispatch({
        type: "MAINT_GET_SINGLE",
        payload: id,
      });
    }
  }, [id]);

  // console.log(maintGetSingle?.parent_mid, id, 'dataof ID');
  

  useEffect(() => {
        if (maintGetSingle?.parent_mid) {
          dispatch({
            type: 'SOP_GET',
            payload: (maintGetSingle?.parent_mid !== undefined) ? maintGetSingle?.parent_mid : id
          })
        }
      }, [maintGetSingle?.parent_mid])

  return (
    <div>
      <PlannedActivity
        type={"Maintenance"}
        formdata={data}
        calendarFreqncy={calendarFreqncy}
        operation={operation}
        id={id}
        submitData_upload={submitData_upload}
        maintGetSingle={maintGetSingle !== null && maintGetSingle}
        submitData_update={submitData_update}
        isSubmitted={isSubmitted}
        sopData={sopData}
      />
    </div>
  );
}

export default Maintenancenew;
