import React, { useEffect, useState } from "react";
import ReportHearder from "./reportHearder";
import ReportTable from "./reportTable";
import moment from "moment";

function ThcLog({
  responseData,
  filterData,
  data1,
  data2,
  data4,
  data5,
  tableHeaders,
  tableHeaders1,
  tableHeaders2,
  logo,
  assetid,
  chklname,
}) {
  const [resdata, setResData] = useState({
    bodyData: tableHeaders,
    logo: "",
  });

  if (data4 && data4?.data == "QAD/023:F4-06") {
  }

  data4[2].data = assetid?.label || "";

  let data3 = {
    data: `${chklname}`,
    colSpan: "7",
  };

  let chunks = [];
  let datesArray = [];
  let headings = [];

  if (data2 && data2?.data == "QAD/023:F4-06") {
    let time = [];
    let performed_by = [];
    let tempmax = [];
    let tempmin = [];
    let relativemax = [];
    let relativemin = [];
    let date = [];
    responseData?.checklist?.forEach((element) => {
      date.push({
        data: moment(element?.ins_sch_date).local().format("DD/MM/YY"),
        colSpan: "2",
        textAlign: "center",
      });
      time.push(moment(element?.ins_sch_date).local().format("hh:mm A"));
      performed_by.push(
        element?.performed_by !== "" && element?.performed_by !== undefined
          ? element?.performed_by
          : "-"
      );
      element?.checkpoints?.forEach((element) => {
        if (element?.checkpoint == "Temperature (Min)") {
          tempmin.push(element?.answer !== "" ? element?.answer : "-");
        } else if (element?.checkpoint == "Temperature (Max)") {
          tempmax.push(element?.answer !== "" ? element?.answer : "-");
        } else if (element?.checkpoint == "Relative Humidity (Min)") {
          relativemin.push(element?.answer !== "" ? element?.answer : "-");
        } else if (element?.checkpoint == "Relative Humidity (Max)") {
          relativemax.push(element?.answer !== "" ? element?.answer : "-");
        }
      });
    });
    const totalItems = time.length;

    for (let i = 0; i < totalItems; ) {
      // Determine chunk size based on index
      const chunkSize = Math.floor(i / 15) % 2 === 0 ? 15 : 16;

      // Slice the arrays
      const obj = {
        time: time.slice(i, i + chunkSize),
        tempmin: tempmin.slice(i, i + chunkSize),
        relativemin: relativemin.slice(i, i + chunkSize),
        tempmax: tempmax.slice(i, i + chunkSize),
        relativemax: relativemax.slice(i, i + chunkSize),
        performed_by: performed_by.slice(i, i + chunkSize),
      };

      let dates = date.slice(i, i + chunkSize);

      // Pad the arrays with '-' if they are shorter than the required chunkSize
      const padArray = (arr, size, padValue) => {
        return arr.length < size
          ? [...arr, ...Array(size - arr.length).fill(padValue)]
          : arr;
      };

      // Fill the arrays with '-' where necessary
      obj.time = padArray(obj.time, chunkSize, "-");
      obj.tempmin = padArray(obj.tempmin, chunkSize, "-");
      obj.relativemin = padArray(obj.relativemin, chunkSize, "-");
      obj.tempmax = padArray(obj.tempmax, chunkSize, "-");
      obj.relativemax = padArray(obj.relativemax, chunkSize, "-");
      obj.performed_by = padArray(obj.performed_by, chunkSize, "-");

      // Pad dates with '-' if needed
      dates = padArray(dates, chunkSize, {
        data: "-",
        colSpan: "2",
        textAlign: "left",
      });

      // Set the heading
      const start = dates[0]?.data; // Use the date from the first element
      const end = dates[dates?.length-1]?.data;
      const startMonth = moment(start, "DD/MM/YY")
        .format("MMMM")
        .toUpperCase();
      const endMonth = moment(end, "DD/MM/YY")
        .format("MMMM")
        .toUpperCase();

      headings.push({
        data: `TEMPERATURE AND RELATIVE HUMIDITY LOG SHEET FOR THE MONTH OF ${
          startMonth === endMonth || endMonth == ""
            ? startMonth
            : `${startMonth} - ${endMonth}`
        }`,
        colSpan: "7",
      });

      // Store the objects and dates
      chunks.push(obj);
      datesArray.push(dates);

      // Move to the next chunk
      i += chunkSize;
    }
  } else if (data2 && data2?.data == "QAD/023:F3-03") {
    let time1 = [];
    let temp1 = [];
    let checkedby1 = [];
    let time2 = [];
    let temp2 = [];
    let checkedby2 = [];
    let time3 = [];
    let temp3 = [];
    let checkedby3 = [];

    let date = [];
    responseData?.checklist?.forEach((element, i) => {
      if (i % 3 == 0) {
        date.push({
          data: moment(element?.ins_sch_date).local().format("DD/MM/YY"),
          colSpan: "2",
          textAlign: "center",
        });
        time1.push(moment(element?.ins_sch_date).local().format("hh:mm A"));
        checkedby1.push(
          element?.performed_by !== "" && element?.performed_by !== undefined
            ? element?.performed_by
            : "-"
        );
        temp1.push(
          element?.checkpoints?.length > 0 &&
            element?.checkpoints[0]?.answer !== ""
            ? element?.checkpoints[0]?.answer
            : "-"
        );
      } else if (i % 3 == 1) {
        time2.push(moment(element?.ins_sch_date).local().format("hh:mm A"));
        checkedby2.push(
          element?.performed_by !== "" && element?.performed_by !== undefined
            ? element?.performed_by
            : "-"
        );
        temp2.push(
          element?.checkpoints?.length > 0 &&
            element?.checkpoints[0]?.answer !== ""
            ? element?.checkpoints[0]?.answer
            : "-"
        );
      } else if (i % 3 == 2) {
        time3.push(moment(element?.ins_sch_date).local().format("hh:mm A"));
        checkedby3.push(
          element?.performed_by !== "" && element?.performed_by !== undefined
            ? element?.performed_by
            : "-"
        );
        temp3.push(
          element?.checkpoints?.length > 0 &&
            element?.checkpoints[0]?.answer !== ""
            ? element?.checkpoints[0]?.answer
            : "-"
        );
      }
    });
    console.log("time1",time1,time2,time3,checkedby1,checkedby2,checkedby3,temp1,temp2,temp3)
    const totalItems = time1.length;

    for (let i = 0; i < totalItems; ) {
      // Determine chunk size based on index
      const chunkSize = Math.floor(i / 15) % 2 === 0 ? 15 : 16;

      // Slice the arrays
      const obj = {
        time1: time1.slice(i, i + chunkSize),
        temp1: temp1.slice(i, i + chunkSize),
        checkedby1 :checkedby1.slice(i, i + chunkSize),
        time2: time2.slice(i, i + chunkSize),
        temp2: temp2.slice(i, i + chunkSize),
        checkedby2 :checkedby2.slice(i, i + chunkSize),
        time3: time3.slice(i, i + chunkSize),
        temp3: temp3.slice(i, i + chunkSize),
        checkedby3 :checkedby3.slice(i, i + chunkSize),
      };

      let dates = date.slice(i, i + chunkSize);

      // Pad the arrays with '-' if they are shorter than the required chunkSize
      const padArray = (arr, size, padValue) => {
        return arr.length < size
          ? [...arr, ...Array(size - arr.length).fill(padValue)]
          : arr;
      };

      // Fill the arrays with '-' where necessary
      obj.time1 = padArray(obj.time1, chunkSize, "-");
      obj.temp1 = padArray(obj.temp1, chunkSize, "-");
      obj.checkedby1 = padArray(obj.checkedby1, chunkSize, "-");
      obj.time2 = padArray(obj.time2, chunkSize, "-");
      obj.temp2 = padArray(obj.temp2, chunkSize, "-");
      obj.checkedby2 = padArray(obj.checkedby2, chunkSize, "-");
      obj.time3 = padArray(obj.time3, chunkSize, "-");
      obj.temp3 = padArray(obj.temp3, chunkSize, "-");
      obj.checkedby3 = padArray(obj.checkedby3, chunkSize, "-");

      // Pad dates with '-' if needed
      dates = padArray(dates, chunkSize, {
        data: "-",
        colSpan: "2",
        textAlign: "left",
      });

      // Set the heading
      const start = dates[0]?.data; // Use the date from the first element
      const end = dates[dates?.length-1]?.data;
      const startMonth = moment(start, "DD/MM/YY")
        .format("MMMM")
        .toUpperCase();
      const endMonth = moment(end, "DD/MM/YY")
        .format("MMMM")
        .toUpperCase();

      headings.push({
        data: `TEMPERATURE AND LOG SHEET FOR THE MONTH OF ${
          startMonth === endMonth || endMonth == ""
            ? startMonth
            : `${startMonth} - ${endMonth}`
        }`,
        colSpan: "7",
      });

      // Store the objects and dates
      chunks.push(obj);
      datesArray.push(dates);

      // Move to the next chunk
      i += chunkSize;
    }
  }

  useEffect(() => {
    setResData({
      ...resdata,
      logo: logo,
    });
  }, [responseData]);

  return (
    <div>
      {chunks?.length > 0 ? (
        chunks?.map((el, i) => {
          return (
            <div
              style={{
                pageBreakBefore: i !== 0 ? "always" : "auto",
                paddingTop: i !== 0 ? "20px" : "0px",
              }}
            >
              <ReportHearder
                data1={data1}
                data2={data2}
                data3={headings[i]}
                custLogo={logo}
              />
              <ReportTable headerData2={data4} />
              <ReportTable
                headerData={data5}
                headerData2={datesArray[i]}
                verticalBodyData2={{
                  bodyData: resdata?.bodyData,
                  data: el,
                }}
              />
            </div>
          );
        })
      ) : (
        <div>
          <ReportHearder
            data1={data1}
            data2={data2}
            data3={data3}
            custLogo={logo}
          />
          <ReportTable
            headerData={data5}
            verticalBodyData2={{
              bodyData: resdata?.bodyData,
              bodyData2:tableHeaders1?tableHeaders1:[],
              bodyData3:tableHeaders2?tableHeaders2:[],
              data: [],
            }}
          />
        </div>
      )}
    </div>
  );
}

export default ThcLog;
