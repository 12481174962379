import React, { useEffect, useState } from "react";
import ReportHearder from "./reportHearder";
import ReportTable from "./reportTable";
import moment from "moment";

function Insecticutor({
  responseData,
  filterData,
  data1,
  data2,
  data4,
  data5,
  tableHeaders,
  tableHeaders1,
  tableHeaders2,
  logo,
  assetid,
  chklname,
}) {
  const [resdata, setResData] = useState({
    bodyData: tableHeaders,
    logo: "",
  });

  let data3 = {
    data: `${chklname}`,
    colSpan: "7",
  };

  let chunks = [];
  let datesArray = [];
  let headings = [];

  let date = [];
  let clean = [];
  let removal = [];
  let dispose = [];
  let tray = [];
  let refix = [];
  let insdoneby = [];
  let inscheckedby = [];
  let arr1 = [];
  let arr2 = [];
  let checkUV = [];
  let checkUVdoneby = [];
  let checkUVcheckedby = [];
  let checkUV1 = [];
  let checkUVdoneby1 = [];
  let checkUVcheckedby1 = [];
  let checkelectric = [];
  let checkelectricdoneby = [];
  let checkelectriccheckedby = [];
  let checkelectric1 = [];
  let checkelectricdoneby1 = [];
  let checkelectriccheckedby1 = [];

  responseData?.checklist?.forEach((element) => {
    date.push({
      data: moment(element?.ins_sch_date).local().format("DD/MM/YY"),
      colSpan: "1",
      textAlign: "center",
    });

    insdoneby.push(element?.performed_by || "-");
    inscheckedby.push(element?.ins_approval_id || "-");

    const checkpointMap = {
      "Clean the unit with cloth.": clean,
      "Remove the bottom tray and clean the tray.": removal,
      "Dispose off dead insects.": dispose,
      "Clean the tray thoroughly with a wet cloth.": tray,
      "Re-fix the tray back to its position.": refix,
    };

    element?.checkpoints?.forEach((checkpointElement) => {
      const targetArray = checkpointMap[checkpointElement?.checkpoint];
      if (targetArray) {
        targetArray.push(checkpointElement?.answer || "-");
      }
    });
  });

  responseData?.maint_checklist?.forEach((element) => {
    const formattedDate = moment(element?.maint_sch_date)
      .local()
      .format("DD/MM/YY");
    const checkpoint = element?.maintenance_activities[0]?.checkpoints[0];
    const performed_by =
      element?.maintenance_activities[0]?.performer_info?.[0]?.performed_by ||
      "-";
    const maint_approval_id = element?.maint_approval_id || "-";
    const cp_answer = checkpoint?.cp_answer || "-";

    if (
      checkpoint?.checkpoint ===
      "Check the UV lamp and electrode working condition."
    ) {
      arr1.push(formattedDate);
      checkUV.push(cp_answer);
      checkUVdoneby.push(performed_by);
      checkUVcheckedby.push(maint_approval_id);
    } else if (checkpoint?.checkpoint === "Check the electric circuit") {
      arr2.push(formattedDate);
      checkelectric.push(cp_answer);
      checkelectricdoneby.push(performed_by);
      checkelectriccheckedby.push(maint_approval_id);
    }
  });

  let i = 0;
  let j = 0;

  date?.forEach((el) => {
    const currentDate = el.data;

    // Handle UV checks
    if (currentDate === arr1[i]) {
      checkUV1.push(checkUV[i]);
      checkUVdoneby1.push(checkUVdoneby[i]);
      checkUVcheckedby1.push(checkUVcheckedby[i]);
      i++;
    } else {
      checkUV1.push("-");
      checkUVdoneby1.push("-");
      checkUVcheckedby1.push("-");
    }

    // Handle electric checks
    if (currentDate === arr2[j]) {
      checkelectric1.push(checkelectric[j]);
      checkelectricdoneby1.push(checkelectricdoneby[j]);
      checkelectriccheckedby1.push(checkelectriccheckedby[j]);
      j++;
    } else {
      checkelectric1.push("-");
      checkelectricdoneby1.push("-");
      checkelectriccheckedby1.push("-");
    }
  });

  const totalItems = date?.length;

  for (let i = 0; i < totalItems;) {
    // Determine chunk size based on index
    const chunkSize = Math.floor(i / 15) % 2 === 0 || responseData?.checklist?.length == 30 ? 15 : 16;

    // Slice the arrays
    const obj = {
      clean: clean.slice(i, i + chunkSize),
      removal: removal.slice(i, i + chunkSize),
      dispose: dispose.slice(i, i + chunkSize),
      tray: tray.slice(i, i + chunkSize),
      refix: refix.slice(i, i + chunkSize),
      insdoneby: insdoneby.slice(i, i + chunkSize),
      inscheckedby: inscheckedby.slice(i, i + chunkSize),
      checkUV1: checkUV1.slice(i, i + chunkSize),
      checkUVdoneby1: checkUVdoneby1.slice(i, i + chunkSize),
      checkUVcheckedby1: checkUVcheckedby1.slice(i, i + chunkSize),
      checkelectric1: checkelectric1.slice(i, i + chunkSize),
      checkelectricdoneby1: checkelectricdoneby1.slice(i, i + chunkSize),
      checkelectriccheckedby1: checkelectriccheckedby1.slice(i, i + chunkSize),
    };

    let dates = date.slice(i, i + chunkSize);

    // Pad the arrays with '-' if they are shorter than the required chunkSize
    const padArray = (arr, size, padValue) => {
      return arr.length < size
        ? [...arr, ...Array(size - arr.length).fill(padValue)]
        : arr;
    };

    obj.clean = padArray(obj.clean, chunkSize, "-");
    obj.removal = padArray(obj.removal, chunkSize, "-");
    obj.dispose = padArray(obj.dispose, chunkSize, "-");
    obj.tray = padArray(obj.tray, chunkSize, "-");
    obj.refix = padArray(obj.refix, chunkSize, "-");
    obj.insdoneby = padArray(obj.insdoneby, chunkSize, "-");
    obj.inscheckedby = padArray(obj.inscheckedby, chunkSize, "-");
    obj.checkUV1 = padArray(obj.checkUV1, chunkSize, "-");
    obj.checkUVdoneby1 = padArray(obj.checkUVdoneby1, chunkSize, "-");
    obj.checkUVcheckedby1 = padArray(obj.checkUVcheckedby1, chunkSize, "-");
    obj.checkelectric1 = padArray(obj.checkelectric1, chunkSize, "-");
    obj.checkelectricdoneby1 = padArray(
      obj.checkelectricdoneby1,
      chunkSize,
      "-"
    );
    obj.checkelectriccheckedby1 = padArray(
      obj.checkelectriccheckedby1,
      chunkSize,
      "-"
    );

    // Pad dates with '-' if needed
    dates = padArray(dates, chunkSize, {
      data: "-",
      colSpan: "1",
      textAlign: "left",
    });

    // Set the heading
    const start = dates[0]?.data; // Use the date from the first element
    const end = dates[dates?.length - 1]?.data;
    const startMonth = moment(start, "DD/MM/YY")
      .format("MMMM")
      .toUpperCase();
    const endMonth = moment(end, "DD/MM/YY")
      .format("MMMM")
      .toUpperCase();
    headings.push({
      data: `${chklname} FOR THE MONTH OF ${
        startMonth === endMonth || endMonth == ""
          ? startMonth
          : `${startMonth} - ${endMonth}`
      }`,
      colSpan: "7",
    });

    // Store the objects and dates
    chunks.push(obj);
    datesArray.push(dates);

    // Move to the next chunk
    i += chunkSize;
  }

  useEffect(() => {
    setResData({
      ...resdata,
      logo: logo,
    });
  }, [responseData]);


  return (
    <div>
      {chunks?.length > 0 ? (
        chunks?.map((el, i) => {
          return (
            <div
              style={{
                pageBreakBefore: i !== 0 ? "always" : "auto",
                paddingTop: i !== 0 ? "20px" : "0px",
              }}
            >
              <ReportHearder
                data1={data1}
                data2={data2}
                data3={headings[i]}
                custLogo={logo}
              />
              <ReportTable
                headerData={data5}
                headerData2={datesArray[i]}
                verticalBodyData2={{
                  bodyData: resdata?.bodyData,
                  name: "Insecticutor",
                  bodyData2: tableHeaders1 ? tableHeaders1 : [],
                  bodyData3: tableHeaders2 ? tableHeaders2 : [],
                  data: el,
                }}
              />
            </div>
          );
        })
      ) : (
        <div>
          <ReportHearder
            data1={data1}
            data2={data2}
            data3={data3}
            custLogo={logo}
          />
          <ReportTable
            headerData={data5}
            verticalBodyData2={{
              bodyData: resdata?.bodyData,
              name: "Insecticutor",
              bodyData2: tableHeaders1 ? tableHeaders1 : [],
              bodyData3: tableHeaders2 ? tableHeaders2 : [],
              data: [],
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Insecticutor;
