import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faClose,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

const WorkStatus = (props) => {
  const {
    sendTabKeyData,
    sendType,
    handleNext,
    handlePrev,
    handleFirstPage,
    handleLastpage,
    handleItemsPerPageChange,
    handleSearchInputChange,
    handleClearButton,
    searchInput,
    data,
    totalPages,
    currentPage,
    itemsPerPage,
    name,
    startDate,
    handleStartDate,
    endDate,
    handleEndDate,
    totalCount,
    handleStartDateoFDashboard,
    handleEndDateoFDashboard,
    yet_to_be_triggered_count,
  } = props;

  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState("All");
  const [key, setKey] = useState("");
  const navigate = useNavigate();
  const sites_id = useSelector(
    (state) => state.auth?.sites_id && state.auth?.sites_id[0]
  );


  const handleSelectType = (val) => {
    // console.log(val);
    sendType(val);
    setSelectedItem(val);
  };
  const handleTabKey = (val) => {
    setKey(val);
    sendTabKeyData(val);
  };

  const convertSecondsToMinSec = (seconds) => {
    // console.log(seconds);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = (seconds % 60).toFixed(0);
    // console.log(minutes, seconds);
    if (!isNaN(minutes)) {
      return {
        hours,
        minutes,
        seconds: remainingSeconds,
      };
    } else {
      return {
        hours: "-",
        minutes: "-",
        seconds: "-",
      };
    }
  };

  useEffect(() => {
    // console.log('LOCATION111', location.state);
    // console.log(location.state?.[2]);
    // console.log(typeof(location.state) === 'object');
    if (
      location.state !== undefined &&
      location.state !== null &&
      name !== "Physical Verification"
    ) {
      setKey(location.state?.[0]);
      sendTabKeyData(location.state?.[0]);
      setSelectedItem(location.state?.[1]);
      // handleStartDate(location.state?.[2])
      // handleEndDate(location.state?.[3])
    }
    if (location?.state?.[0] === undefined) {
      setKey(name === "On - Demand" ? "inprogress" : "total");
      sendTabKeyData(name === "On - Demand" ? "inprogress" : "total");
      setSelectedItem("All");
    }
    if (
      location?.state !== null &&
      location?.state?.[2] !== null &&
      location?.state !== undefined
    ) {
      // console.log('LOCATION', location.state);
      // handleStartDateoFDashboard(
      //   moment(location?.state[2])?.format("YYYY-MM-DD")
      // );
      // handleEndDateoFDashboard(moment(location?.state[3]).format("YYYY-MM-DD"));
    }
  }, [location?.state]);

  useEffect(() => { }, [selectedItem, props]);

  return (
    <div>
      <div
        className="pt-5"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* {console.log('NAME', name)} */}
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => handleTabKey(k)}
          className="m-3 headings"
        >
          {name === "Ticket" ? (
            <Tab eventKey="total" title="Total Raised"></Tab>
          ) : name === "Inspection" ? (
            <Tab eventKey="total" title="Total Triggered"></Tab>
          ) : name === "Maintenance" ? (
            <Tab eventKey="total" title="Total Planned"></Tab>
          ) : (
            <></>
          )}
          &nbsp;
          {/* <Tab eventKey="assigned" title="Assigned"></Tab>
          &nbsp; */}
          {name === "On - Demand" && (
            <Tab eventKey="inprogress" title="In Progress"></Tab>
          )}
          &nbsp;
          <Tab
            eventKey="waiting_for_approval"
            title="Waiting for Approval"
          ></Tab>
          &nbsp;
          
          
          <Tab eventKey="approved" title="Completed"></Tab>
          
          &nbsp;
          <Tab eventKey="reopened" title="Reopened"></Tab>
          &nbsp;
          {
            name === "Ticket" ? (
              <Tab eventKey="reassigned" title="Reassigned"></Tab>
            ) : (
              <></>
            )
          }
          &nbsp;
          {name === "Ticket" ? (
            <Tab eventKey="yet_to_be_assigned" title="Yet to Assign"></Tab>
          ) : (<></>)}
{/*           
          &nbsp;
          {name === "Ticket" ? (
            <Tab eventKey="assigned" title="Assigned"></Tab>
          ) : (<></>)}
           */}
          &nbsp;
         
          {name === "On - Demand" && (
            <Tab eventKey="save_as_draft" title="Save as Draft"></Tab>
          )}
          {/* &nbsp;
          {name === "Ticket" ? (
            <Tab eventKey="rejected" title="Rejected"></Tab>
          ) : (<></>)}
          &nbsp;
          
          {name === "Ticket" ? (
            <Tab eventKey="cancelled" title="Cancelled"></Tab>
          ) : (<></>)} */}
          &nbsp;
          {name === "Inspection" || name === "Maintenance" ? (
            <Tab eventKey="skipped" title="Skipped"></Tab>
          ) : (
            <></>
          )}
          &nbsp;
          {/* <Tab eventKey="rejected" title="Rejected"></Tab>
          &nbsp;
          <Tab eventKey="cancelled" title="Cancelled"></Tab>
          &nbsp; */}
          &nbsp;
        </Tabs>
        <div
          style={{
            flex: "right",
            margin: "10px",
            className: "m-3 headings",
            display: "flex",
            gap: "5%",
          }}
        >
          <Dropdown>
            <Dropdown.Toggle className="custom-button">
              {selectedItem
                ? selectedItem.charAt(0).toUpperCase() + selectedItem.slice(1)
                : "All"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleSelectType("Asset")}>
                Asset
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleSelectType("Device")}>
                Device
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleSelectType("Facility")}>
                Facility
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => handleSelectType("All")}>
                All
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="col-md-12">
        <div
          style={{
            fontSize: "20px",
            // paddingLeft: "100px",
            // paddingTop: "-55px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {selectedItem.charAt(0).toUpperCase() + selectedItem.slice(1)} -{" "}
          {name} List
          <div style={{ float: "right" }}>
            <form className="mb-4">
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={handleSearchInputChange}
                style={{
                  border: "1px solid steelblue",
                  fontSize: "15px",
                  padding: "4px",
                }}
              />
              <span
                type="reset"
                onClick={handleClearButton}
                style={{ paddingLeft: "8px", marginRight: "25px" }}
              >
                <FontAwesomeIcon icon={faClose} size="lg" className="pt-3" />
              </span>
            </form>
          </div>
          <div
            style={{
              display: "flex",
              float: "left",
            }}
          >
            <div>
              <label>Start Date</label>
              <input
                type="date"
                placeholder="Start Date"
                value={startDate}
                onChange={handleStartDate}
                min={
                  sites_id === "6422f56e26037ff46450359d" ? "2024-06-21" : ""
                }
                style={{
                  border: "1px solid steelblue",
                  fontSize: "15px",
                  padding: "5px",
                  margin: "5px",
                }}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div>
              <label>End Date</label>
              <input
                type="date"
                placeholder="End Date"
                value={endDate}
                onChange={handleEndDate}
                min={
                  sites_id === "6422f56e26037ff46450359d" ? "2024-06-21" : ""
                }
                style={{
                  border: "1px solid steelblue",
                  fontSize: "15px",
                  padding: "5px",
                  margin: "5px",
                }}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
          </div>
          <div>
            {key === "total" &&
              (name === "Ticket" ? (
                <div>Total Ticket</div>
              ) : (
                <div>Total Planned</div>
              ))}
            {key === "inprogress" && <div>In Progress</div>}
            {key !== "waiting_for_approval" &&
              key !== "approved" &&
              key !== "total" &&
              key !== "inprogress" &&
              key !== "reopened" &&
              key !== "yet_to_be_assigned" &&
              key !== "reassigned" && (
                <div>{key.charAt(0).toUpperCase() + key.slice(1)}</div>
              )}
            {key === "waiting_for_approval" && <div>Waiting for Approval</div>}
            {key === "approved" && <div>Completed</div>}
            {key === "reassigned" && <div>Re - Assigned</div>}
            {key === "reopened" && <div>Re - Opened</div>}
            {key === "yet_to_be_assigned" && <div>Unassigned</div>}
          </div>
        </div>
        <div
          style={{
            // border: '1px solid',
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            padding: "0.5rem 2rem 0.5rem 0",
          }}
        >
          <div
            style={{
              float: "right",
              // border: '1px solid',
              width: "98%",
              margin: "auto",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {totalCount !== 0 && (
              <div>
                {(key == "total" && name == "Inspection") ||
                  name == "Maintenance" ? (
                  <p
                    style={{
                      margin: "0",
                      color: "gray",
                    }}
                  >
                    Yet To Be Triggered -{" "}
                    {props?.ins_count?.yet_to_be_triggered_count
                      ? props?.ins_count?.yet_to_be_triggered_count
                      : 0}
                  </p>
                ) : key === "pending" ? (
                  <p
                    style={{
                      margin: "0",
                      color: "gray",
                    }}
                  >
                    {/* Waiting For Approval - {props?.ins_count?.waiting_for_approval_count}, Yet To Be Triggered - {props?.ins_count?.yet_to_be_triggered_count},Inprogress - {props?.ins_count?.inprogress_count} */}
                    {/* Yet To Be Triggered - {props?.ins_count?.yet_to_be_triggered_count ? props?.ins_count?.yet_to_be_triggered_count : 0} */}
                  </p>
                ) : (
                  <p
                    style={{
                      margin: "0",
                      color: "gray",
                    }}
                  >
                    {/* Yet To Be Triggered - {props?.yet_to_be_triggered_count ? props?.yet_to_be_triggered_count : 0} */}
                  </p>
                )}
              </div>
            )}
            {/* {(currentPage - 1) * itemsPerPage + (index + 1)} */}
            {totalCount !== 0 && (
              <div>
                <p
                  style={{
                    margin: "0",
                    color: "gray",
                  }}
                >
                  Showing {(currentPage - 1) * itemsPerPage + 1} -{" "}
                  {(+currentPage - 1) * +itemsPerPage + Number(itemsPerPage) >
                    totalCount
                    ? totalCount
                    : (+currentPage - 1) * +itemsPerPage +
                    Number(itemsPerPage)}{" "}
                  results of {totalCount}
                </p>
              </div>
            )}
          </div>
        </div>
        {/* Table to view Maintenance list */}
        <div className="mb-6">
          <table className="table-bordered" width="100%">
            <thead>
              {/* Table heading to display list of customers */}
              <tr
                style={{
                  textAlign: "center",
                  backgroundColor: "steelblue",
                  padding: "8px",
                  color: "#fff",
                }}
              >
                <th
                  style={{
                    padding: "8px",
                  }}
                >
                  S. No
                </th>
                {name === "Ticket" ? (
                  <th
                    scope="col"
                    style={{ textAlign: "left", paddingLeft: "5px" }}
                  >
                    {name} ID
                  </th>
                ) : null}
                {/* {name === "Ticket" ? (
                  <th
                    scope="col"
                    style={{ textAlign: "left", paddingLeft: "5px" }}
                  >
                    Type
                  </th>
                ) : null} */}
                <th
                  scope="col"
                  style={{ textAlign: "left", paddingLeft: "5px" }}
                >
                  {name} Name
                </th>
                <th
                  scope="col"
                  style={{ textAlign: "left", paddingLeft: "5px" }}
                >
                  {selectedItem !== "All"
                    ? `${selectedItem?.charAt(0).toUpperCase() +
                    selectedItem?.slice(1)
                    } ID`
                    : "Asset / Device / Facility - ID"}
                </th>
                <th
                  scope="col"
                  style={{ textAlign: "left", paddingLeft: "5px" }}
                >
                  {selectedItem !== "All"
                    ? `${selectedItem?.charAt(0).toUpperCase() +
                    selectedItem?.slice(1)
                    } Name`
                    : "Asset / Device / Facility - Name"}
                </th>
                {name === "Ticket" ? (
                  <th
                    scope="col"
                    style={{ textAlign: "center", paddingLeft: "5px" }}
                  >
                    Created Date & Time
                  </th>
                ) : (
                  <th
                    scope="col"
                    style={{ textAlign: "center", paddingLeft: "5px" }}
                  >
                    Planned Date & Time
                  </th>
                )}
                {(key === "waiting_for_approval" || key === "approved") && (
                  <th
                    scope="col"
                    style={{ textAlign: "center", paddingLeft: "5px" }}
                  >
                    Performed Date & Time
                  </th>
                )}
                {/* {(key === "waiting_for_approval" || key === "approved") && (
                  <th
                    scope="col"
                    style={{ textAlign: "center", paddingLeft: "5px" }}
                  >
                    {name} Duration
                  </th>
                )} */}
                {name === "Ticket" ? (
                  <th scope="col">Criticality</th>
                ) : (
                  <th scope="col">Priority</th>
                )}
                {name === "Ticket" && (key === "waiting_for_approval" || key === "assigned" || key === "reassigned" || key === "rejected" || key === "yet_to_be_assigned" || key === "cancelled" || key === "reopened") &&
                  <th
                    scope="col"
                    // colSpan="4"
                    className="actionColumn"
                    style={{ textAlign: "center" }}
                  >
                    Action
                  </th>
                }

                {key === "approved" && (
                  <th
                    scope="col"
                    // colSpan="4"
                    className="actionColumn"
                    style={{ textAlign: "center" }}
                  >
                    Action
                  </th>
                )}
              </tr>
            </thead>
            {/* Maintenance details based on status are mapped to read */}
            {data !== null && data.length > 0 ? (
              data.map((eachData, index) => {
                const { hours, minutes, seconds } = convertSecondsToMinSec(
                  eachData?.maintenance?.maintenance_duration ||
                  eachData?.inspection?.inspection_duration ||
                  eachData?.performance?.actual_duration
                );
                return (
                  <tbody key={index} style={{ padding: "15px" }}>
                    <tr style={{ padding: "20px" }}>
                      <td style={{ textAlign: "center", padding: "8px" }}>
                        {(currentPage - 1) * itemsPerPage + (index + 1)}
                      </td>
                      {name === "Ticket" ? (
                        <td style={{ textAlign: "left", paddingLeft: "5px" }}>
                          {eachData?.maintenance?.maintenance_name ||
                            eachData?.inspection?.inspection_name || eachData?.id}
                        </td>
                      ) : null}
                      <td style={{ textAlign: "left", paddingLeft: "5px" }}>
                        {eachData?.maintenance?.maintenance_name ||
                          eachData?.inspection?.inspection_name || eachData?.name}
                      </td>
                      <td style={{ textAlign: "left", paddingLeft: "5px" }}>
                        {eachData?.parent?.parent_id}
                      </td>
                      <td style={{ textAlign: "left", paddingLeft: "5px" }}>
                        {eachData.parent?.parent_name}
                      </td>
                      <td style={{ textAlign: "center", paddingLeft: "5px" }}>
                        {/* {((eachData?.maintenance?.maint_sch_date) || (eachData?.inspection?.ins_sch_date)) ?
                          moment(eachData?.maintenance?.maint_sch_date)
                            .format("LLL") || moment(eachData?.inspection?.ins_sch_date)
                              .format("LLL") : "-"
                        } */}
                        {eachData?.maintenance?.maint_sch_date
                          ? moment
                            .utc(eachData?.maintenance?.maint_sch_date)
                            .local()
                            .format("LLL")
                          : eachData?.inspection?.ins_sch_date
                            ? moment
                              .utc(eachData?.inspection?.ins_sch_date)
                              .local()
                              .format("LLL")
                            : eachData?.createdAt ? moment
                              .utc(eachData?.createdAt)
                              .local()
                              .format("LLL") : '-'}
                      </td>
                      {(key === "waiting_for_approval" ||
                        key === "approved") && (
                          <td style={{ textAlign: "center" }}>                            
                            {eachData?.maintenance?.performed_date && eachData?.maintenance?.performed_date!==null
                              ? moment
                                .utc(eachData?.maintenance?.performed_date)
                                .local()
                                .format("LLL")
                              : eachData?.inspection?.end_date
                                ? moment
                                  .utc(eachData?.inspection?.end_date)
                                  .local()
                                  .format("LLL")
                                : eachData?.performance?.performed_date ? moment
                                  .utc(eachData?.maintenance?.end_date)
                                  .local()
                                  .format("LLL") : '-'}
                          </td>
                        )}
                      {/* {(key === "waiting_for_approval" ||
                        key === "approved") && (
                          <td style={{ textAlign: "center", paddingLeft: "5px" }}>
                            {hours} hr {minutes} min {seconds} sec
                          </td>
                        )} */}
                      <td style={{ textAlign: "center" }}>
                        {(eachData?.maintenance || eachData?.inspection) &&
                          (eachData?.maintenance?.priority
                            ?.charAt(0)
                            ?.toUpperCase() +
                            eachData?.maintenance?.priority?.slice(1) ||
                            eachData?.inspection?.priority
                              ?.charAt(0)
                              ?.toUpperCase() +
                            eachData?.inspection?.priority?.slice(1))}
                        {name === "Ticket" && eachData?.service?.criticality !== '' ? eachData?.service?.criticality : '-'}
                      </td>

                      {/* Preview Report button */}
                      {name === "Ticket" && (key === "approved" || key === "waiting_for_approval" || key === "assigned" || key === "reassigned" || key === "rejected" || key === "yet_to_be_assigned" || key === "cancelled" || key === "reopened") &&
                        <td style={{ textAlign: "center" }}>
                          {eachData?._id && (
                            <button
                              type="button"
                              data-tip
                              data-for="viewTip"
                              onClick={() =>
                                navigate(
                                  `/ticket/report/${eachData?._id}`,
                                  {
                                    state: [
                                      key,
                                      selectedItem,
                                      currentPage,
                                      itemsPerPage,
                                      startDate,
                                      endDate,
                                      searchInput,
                                      name,
                                    ],
                                  }
                                )
                              }
                              style={{
                                border: "none",
                                borderRadius: "10px",
                                backgroundColor: "#E8F5F3",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faFilePdf}
                                size="lg"
                                className="icons"
                              />
                              <ReactTooltip
                                id="viewTip"
                                place="bottom"
                                effect="solid"
                              >
                                Preview Report
                              </ReactTooltip>
                            </button>
                          )}
                        </td>}
                      {key === "approved" && (
                        <td style={{ textAlign: "center" }}>
                          {eachData?.inspection?._id && (
                            <button
                              type="button"
                              data-tip
                              data-for="viewTip"
                              onClick={() =>
                                navigate(
                                  `/inspection/report/${eachData?.inspection?._id}`,
                                  {
                                    state: [
                                      key,
                                      selectedItem,
                                      currentPage,
                                      itemsPerPage,
                                      startDate,
                                      endDate,
                                      searchInput,
                                      name,
                                    ],
                                  }
                                )
                              }
                              style={{
                                border: "none",
                                borderRadius: "10px",
                                backgroundColor: "#E8F5F3",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faFilePdf}
                                size="lg"
                                className="icons"
                              />
                              <ReactTooltip
                                id="viewTip"
                                place="bottom"
                                effect="solid"
                              >
                                Preview Report
                              </ReactTooltip>
                            </button>
                          )}
                          {eachData?.maintenance?._id && (
                            <button
                              type="button"
                              data-tip
                              data-for="viewTip"
                              onClick={() =>
                                navigate(
                                  `/maintenance/report/${eachData?.maintenance?._id}`,
                                  {
                                    state: [
                                      key,
                                      selectedItem,
                                      currentPage,
                                      itemsPerPage,
                                      startDate,
                                      endDate,
                                      searchInput,
                                      name,
                                    ],
                                  }
                                )
                              }
                              style={{
                                border: "none",
                                borderRadius: "10px",
                                backgroundColor: "#E8F5F3",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faFilePdf}
                                size="lg"
                                className="icons"
                              />
                              <ReactTooltip
                                id="viewTip"
                                place="bottom"
                                effect="solid"
                              >
                                Preview Report
                              </ReactTooltip>
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                  </tbody>
                );
              })
            ) : (
              <tbody>
                <tr style={{ textAlign: "center", height: "75px" }}>
                  <td colSpan="11">No Data Available</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        {/* pagination */}
        {data !== null && data.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
              padding: "10px",
            }}
          >
            <div className="mb-6">
              <label>Items per page:</label> &nbsp;
              <select
                onChange={(e) => handleItemsPerPageChange(e.target.value)}
                style={{ borderRadius: "8px", cursor: "pointer" }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
                {/* <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={300}>300</option>
              <option value={400}>400</option>
              <option value={500}>500</option> */}
              </select>
            </div>

            {/* //pagination */}
            <div style={{ paddingRight: "25px" }} className="pagination">
              <button
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "#fff",
                }}
                disabled={currentPage === 1}
                onClick={handleFirstPage}
              >
                {currentPage === 1 ? (
                  <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ccc" />
                ) : (
                  <FontAwesomeIcon icon={faAngleDoubleLeft} color="steelblue" />
                )}
              </button>
              <button
                style={{
                  padding: "5px",
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "#fff",
                }}
                disabled={currentPage === 1}
                onClick={handlePrev}
              >
                {/* Prev */}
                {currentPage === 1 ? (
                  <FontAwesomeIcon icon={faAngleLeft} color="#ccc" />
                ) : (
                  <FontAwesomeIcon icon={faAngleLeft} color="steelblue" />
                )}
              </button>
              <span style={{ padding: "10px" }}>
                Showing {currentPage} of {totalPages} pages
              </span>
              {totalPages === 0 || currentPage === totalPages ? (
                <>
                  <button
                    style={{
                      padding: "5px",
                      cursor: "pointer",
                      backgroundColor: "#fff",
                      border: "none",
                    }}
                    disabled={true}
                  >
                    <FontAwesomeIcon icon={faAngleRight} color="#ccc" />
                  </button>
                  <button
                    style={{
                      padding: "5px",
                      cursor: "pointer",
                      backgroundColor: "#fff",
                      border: "none",
                    }}
                    disabled={true}
                  >
                    <FontAwesomeIcon icon={faAngleDoubleRight} color="#ccc" />
                  </button>
                </>
              ) : (
                <>
                  <button
                    style={{
                      padding: "5px",
                      cursor: "pointer",
                      backgroundColor: "#fff",
                      border: "none",
                    }}
                    disabled={currentPage === totalPages}
                    onClick={handleNext}
                  >
                    <FontAwesomeIcon icon={faAngleRight} color="steelblue" />
                  </button>
                  <button
                    style={{
                      padding: "5px",
                      cursor: "pointer",
                      backgroundColor: "#fff",
                      border: "none",
                    }}
                    disabled={currentPage === totalPages}
                    onClick={handleLastpage}
                  >
                    <FontAwesomeIcon
                      icon={faAngleDoubleRight}
                      color="steelblue"
                    />
                  </button>
                </>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default WorkStatus;
