import React from "react";
import {
  Button,
  Container,
  Dropdown,
  Offcanvas,
  NavDropdown,
  Accordion,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowDownUpAcrossLine,
  faBars,
  faClock,
  faCheck,
  faBarsStaggered,
  faArrowDown,
  faFileLines,
  faListCheck,
  faList,
  faPlus,
  faGears,
  faGear,
  faPager,
  faPenToSquare,
  faUser,
  faCalendarCheck,
  faUserGear,
  faUserGroup,
  faUserPlus,
  faFileText,
  faChartColumn,
  faChartSimple,
  faCalendar,
  faEye,
  faFolderTree,
  faWrench,
  faTicket,
  faRectangleList,
  faFileCircleCheck,
  faClipboardCheck,
  faLocation,
  faBuilding,
  faBraille,
  faObjectGroup,
  faQrcode,
  faSquareRss,
  faShuffle,
  faFileArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./sideBar.css";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.auth.role);
  const accessData = useSelector((state) => state.access.accessData);
  const customerAccess = useSelector((state) => state.access.customerAccess);
  const ticketAccess = useSelector((state) => state.access.breakdown);
  const siteAccess = useSelector((state) => state.access.site);
  const accessAsset = useSelector((state) => state.access.asset);
  const accounting = useSelector((state) => state.access.accounting);
  const assetInspection = useSelector((state) => state.access.assetInspection);
  const assetMaintenance = useSelector(
    (state) => state.access.assetMaintenance
  );
  const accessQR = useSelector((state) => state.access.qrGenerator);
  const accessUser = useSelector((state) => state.access.user);
  const accessWorkOrder = useSelector((state) => state.access.workOrder);
  const accessPlant = useSelector((state) => state.access.plant);
  const transferDispose = useSelector((state) => state.access.transfer_dispose);
  const depreciationCalculation = useSelector(
    (state) => state.access.depreciation_calculation
  );
  const depreciationReport = useSelector(
    (state) => state.access.depreciation_report
  );
  const fixedAssetMaster = useSelector(
    (state) => state.access.fixed_asset_master
  );
  const assetVerification = useSelector(
    (state) => state.access.asset_verification
  );
  const auditTrial = useSelector((state) => state.access.audit_trial);
  const accessCWIP = useSelector((state) => state.access.cwip_list);
  const accessCapitalised = useSelector(
    (state) => state.access.capitalised_list
  );
  const verificationReport = useSelector(
    (state) => state.access.verification_report
  );
  const reportMedo = useSelector((state) => state.access.custom_report);
  const accessOnDemand = useSelector(
    (state) => state.access.onDemandInspection
  );

  const deviceMaintenance = useSelector(
    (state) => state.access.deviceMaintenance
  );
  const facilityMaintenance = useSelector(
    (state) => state.access.facilityMaintenance
  );
  const deviceInspection = useSelector(
    (state) => state.access.deviceInspection
  );
  const facilityInspection = useSelector(
    (state) => state.access.facilityInspection
  );
  // console.log(accessCWIP, 'accessCWIP')
  // console.log("accessCapitalised", accessCapitalised);
  const [show, setShow] = useState(false);
  const [hideNavItems, setHideNavItems] = useState(true);
  const [open, setopen] = useState(true);

  const closeSidebar = () => setShow(false);
  const showSidebar = () => setShow(true);

  const toggleOpen = () => {
    setopen(!open);
    closeSidebar(true);
  };

  const handleSidenavHide = () => {
    if (showSidebar()) {
      hideNavItems();
    }
  };

  useEffect(() => {
    const roleCode = role[0];
    // console.log(accessData);
    // console.log(roleCode);
    if (accessData === null && roleCode !== null) {
      dispatch({
        type: "ACCESS_GET_LIST",
        payload: roleCode,
      });
    }
  }, [accessData, customerAccess]);

  useEffect(() => {
    // console.log(accessWorkOrder);
  }, [accessWorkOrder]);

  return (
    <div>
      <Nav.Link
        onClick={handleSidenavHide}
        style={{ flex: "center" }}
      // className="header"
      >
        <FontAwesomeIcon icon={faBars} className="mx-2" />
      </Nav.Link>
      <Offcanvas
        show={show}
        onHide={closeSidebar}
        style={{ width: "40vh", backgroundColor: "white" }}
      >
        <Offcanvas.Header></Offcanvas.Header>
        <Offcanvas.Body style={{ paddingLeft: "15px" }}>
          {/*....................... HOME ...................... */}
          <Nav.Link
            onClick={() => {
              navigate("/home");
              toggleOpen();
            }}
            style={{ paddingLeft: "5px" }}
            className="p-2"
          >
            <span className="icon-V1-assets-icon sideNavIcons"></span>
            Home
          </Nav.Link>
          {/* <div>
              <Accordion>
                <Accordion.Item
                  eventKey="2"
                  style={{
                    border: "none",
                    background: "none",
                    position: "relative",
                    paddingBottom: "15px",
                  }}
                >
                  <Accordion.Header className="accordianHeader">
                  <span className="icon-V1-assets-icon sideNavIcons"></span>
                    Home
                  </Accordion.Header>
                  <Accordion.Body>
                    <Nav.Link
                      onClick={() => {
                        navigate("/home");
                        toggleOpen();
                      }}
                      style={{ color: "#000", padding: "10px" }}
                    >
                      <FontAwesomeIcon
                        icon={faChartSimple}
                        className="sideNavIcons"
                      />
                      Dashboard
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => {
                        navigate("/trend");
                        toggleOpen();
                      }}
                      style={{ color: "#000", padding: "10px" }}
                    >
                      <FontAwesomeIcon
                        icon={faChartColumn}
                        className="sideNavIcons"
                      />
                      Trend
                    </Nav.Link>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div> */}
          {/*....................... Customer ...................... */}

          {customerAccess !== null && customerAccess.r ? (
            <Nav.Link
              onClick={() => {
                navigate("/customerlist");
                toggleOpen();
              }}
              style={{ paddingLeft: "8px" }}
              className="p-2"
            >
              <span className="icon-V1-customers-icon sideNavIcons"></span>
              Customer
            </Nav.Link>
          ) : (
            <div></div>
          )}
          {/*....................... User ...........................*/}
          {accessUser !== null && accessUser.r ? (
            <Accordion>
              <Accordion.Item
                eventKey="3"
                style={{
                  border: "none",
                  background: "none",
                  position: "relative",
                  paddingBottom: "15px",
                }}
              >
                <Accordion.Header className="accordianHeader">
                  <FontAwesomeIcon
                    icon={faUserGroup}
                    className="sideNavIcons"
                  />
                  User Management
                </Accordion.Header>
                <Accordion.Body>
                  <Nav.Link
                    onClick={() => {
                      navigate("/user/list");
                      toggleOpen();
                    }}
                    className="p-2"
                  >
                    <FontAwesomeIcon
                      icon={faUserPlus}
                      className="sideNavIcons"
                    />
                    User Create & List
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => {
                      navigate("/user/role");
                      toggleOpen();
                    }}
                    className="p-2"
                  >
                    <FontAwesomeIcon icon={faUser} className="sideNavIcons" />
                    User Role
                  </Nav.Link>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : (
            <div></div>
          )}

          {/*....................... Site ...........................*/}
          {siteAccess !== null && siteAccess.r ? (
            <Nav.Link
              onClick={() => {
                navigate("/sitelist");
                toggleOpen();
              }}
              className="p-2"
            >
              <span className="icon-V1-sites sideNavIcons"></span>
              Site
            </Nav.Link>
          ) : (
            <div></div>
          )}

          {/*....................... Asset Management ...................... */}
          <div>
            <div>
              {(accessAsset !== null && accessAsset?.r) || accounting?.r ? (
                <Accordion>
                  <Accordion.Item
                    eventKey="0"
                    style={{
                      border: "none",
                      background: "none",
                      position: "relative",
                      paddingBottom: "15px",
                    }}
                  >
                    <Accordion.Header className="accordianHeader">
                      <FontAwesomeIcon
                        icon={faGears}
                        className="sideNavIcons"
                      />
                      Asset Management
                    </Accordion.Header>
                    <Accordion.Body>
                      <div>
                        {accessAsset !== null && accessAsset.c ? (
                          <Nav.Link
                            className="p-2"
                            onClick={() => {
                              navigate("/select/asset");
                              toggleOpen();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faAdd}
                              className="sideNavIcons"
                            />
                            {/* icon-V1-Add-dark-mode */}
                            Create Asset / Facility / Device
                          </Nav.Link>
                        ) : (
                          <div></div>
                        )}
                      </div>
                      <div>
                        {accessAsset !== null && accessAsset.r ? (
                          <Nav.Link
                            className="p-2"
                            onClick={() => {
                              navigate("/assetlist");
                              toggleOpen();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              className="sideNavIcons"
                            />
                            View Asset / Device/ Facility
                          </Nav.Link>
                        ) : (
                          <div></div>
                        )}
                        {accessAsset !== null &&
                          (assetMaintenance.r || assetInspection.r) ? (
                          <Nav.Link
                            className="p-2"
                            onClick={() => {
                              navigate("/inspectionlist");
                              toggleOpen();
                            }}
                          >
                            <span className=" icon-V1-Schedule-report-icon sideNavIcons"></span>
                            Schedule Management
                          </Nav.Link>
                        ) : (
                          <div></div>
                        )}
                      </div>
                      <div>
                        {accessQR !== null && accessQR.c ? (
                          <Nav.Link
                            className="p-2"
                            onClick={() => {
                              navigate("/qrcode-generator");
                              toggleOpen();
                            }}
                          >
                            <span className="icon-V1-QR-generator-icon sideNavIcons"></span>
                            QR Generator
                          </Nav.Link>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <div>
            {/* <Accordion>
              <Accordion.Item
                eventKey="2"
                style={{
                  border: "none",
                  background: "none",
                  position: "relative",
                  paddingBottom: "15px",
                }}
              >
                <Accordion.Header className="accordianHeader">
                  <FontAwesomeIcon
                    icon={faTicket}
                    className="sideNavIcons"
                  />
                  Ticket Management
                </Accordion.Header>
                <Accordion.Body>
                  <Nav.Link
                    onClick={() => {
                      navigate("/tat-ticket/list");
                      toggleOpen();
                    }}
                    style={{ color: "#000", padding: "10px" }}
                  >
                    <FontAwesomeIcon
                      icon={faRectangleList}
                      className="sideNavIcons"
                    />
                    TAT
                  </Nav.Link>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
          </div>
          {/* ................................................................. */}

          {accounting && accounting.c && (
            <Accordion>
              <Accordion.Item
                eventKey="3"
                style={{
                  border: "none",
                  background: "none",
                  position: "relative",
                  paddingBottom: "15px",
                }}
              >
                <Accordion.Header className="accordianHeader">
                  <FontAwesomeIcon icon={faGear} className="sideNavIcons" />
                  Fixed Asset
                </Accordion.Header>
                <Accordion.Body>
                  <Nav.Link
                    onClick={() => {
                      navigate("/register");
                      toggleOpen();
                    }}
                    style={{ paddingLeft: "5px" }}
                    className="p-2"
                  >
                    <FontAwesomeIcon icon={faPlus} className="sideNavIcons" />
                    Asset Registration
                  </Nav.Link>
                  {accessCWIP !== undefined && accessCWIP?.c === true && (
                    <Nav.Link
                      onClick={() => {
                        navigate("/cwip");
                        toggleOpen();
                      }}
                      style={{ paddingLeft: "5px" }}
                      className="p-2"
                      disabled={accessCWIP?.d}
                    >
                      <FontAwesomeIcon icon={faList} className="sideNavIcons" />
                      CWIP List
                    </Nav.Link>
                  )}
                  {accessCapitalised !== undefined &&
                    accessCapitalised?.c === true && (
                      <Nav.Link
                        onClick={() => {
                          navigate("/capitalised");
                          toggleOpen();
                        }}
                        style={{ paddingLeft: "5px" }}
                        className="p-2"
                        disabled={accessCapitalised?.d}
                      >
                        <FontAwesomeIcon
                          icon={faListCheck}
                          className="sideNavIcons"
                        />
                        Capitalised List
                      </Nav.Link>
                    )}
                  {accessCapitalised !== undefined &&
                    accessCapitalised?.c === true && (
                      <Nav.Link
                        onClick={() => {
                          navigate("/expensed");
                          toggleOpen();
                        }}
                        style={{ paddingLeft: "5px" }}
                        className="p-2"
                        disabled={accessCapitalised?.d}
                      >
                        <FontAwesomeIcon
                          icon={faBarsStaggered}
                          className="sideNavIcons"
                        />
                        Expensed List
                      </Nav.Link>
                    )}
                  {depreciationCalculation !== undefined &&
                    depreciationCalculation?.c === true && (
                      <Nav.Link
                        onClick={() => {
                          navigate("/depreciation");
                          toggleOpen();
                        }}
                        style={{ paddingLeft: "5px" }}
                        className="p-2"
                        disabled={depreciationCalculation?.d}
                      >
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="sideNavIcons"
                        />
                        Depreciation Run
                      </Nav.Link>
                    )}
                  {transferDispose !== undefined &&
                    transferDispose?.c === true &&
                    transferDispose?.u === true && (
                      <Nav.Link
                        onClick={() => {
                          navigate("/transfer-dispose");
                          toggleOpen();
                        }}
                        style={{ paddingLeft: "5px" }}
                        className="p-2"
                        disabled={transferDispose?.d}
                      >
                        <FontAwesomeIcon
                          icon={faArrowDownUpAcrossLine}
                          className="sideNavIcons"
                        />
                        Transfer & Disposal
                      </Nav.Link>
                    )}
                  {/* <Nav.Link
                    onClick={() => {
                      navigate("/depreciation");
                      toggleOpen();
                    }}
                    style={{ paddingLeft: "5px" }}
                    className="p-2"
                  >
                    <FontAwesomeIcon icon={faArrowDown} className="sideNavIcons" />
                    Depreciation Run
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => {
                      navigate("/depreciationreport");
                      toggleOpen();
                    }}
                    style={{ paddingLeft: "5px" }}
                    className="p-2"
                  >
                    <FontAwesomeIcon icon={faFileLines} className="sideNavIcons" />
                    Depreciation Report
                  </Nav.Link> */}
                  {/* <Nav.Link
                    onClick={() => {
                      navigate("/verification");
                      toggleOpen();
                    }}
                    style={{ paddingLeft: "5px" }}
                    className="p-2"
                  >
                    <FontAwesomeIcon icon={faCheck} className="sideNavIcons" />
                    Physical Verification
                  </Nav.Link> */}
                  {/* <Nav.Link
                    onClick={() => {
                      navigate("/period-closure");
                      toggleOpen();
                    }}
                    style={{ paddingLeft: "5px" }}
                    className="p-2"
                  >
                    <FontAwesomeIcon icon={faClock} className="sideNavIcons" />
                    Period Closure
                  </Nav.Link> */}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}

          {/* .......................... Work Order ............................... */}
          <div>
            {accessWorkOrder !== undefined && accessWorkOrder?.r === true ? (
              <div>
                <Accordion>
                  <Accordion.Item
                    eventKey="2"
                    style={{
                      border: "none",
                      background: "none",
                      position: "relative",
                      paddingBottom: "15px",
                    }}
                  >
                    <Accordion.Header className="accordianHeader">
                      <FontAwesomeIcon
                        icon={faFolderTree}
                        className="sideNavIcons"
                      />
                      Work Order
                    </Accordion.Header>
                    <Accordion.Body>
                      {(assetInspection?.r ||
                        deviceInspection?.r ||
                        facilityInspection?.r) && (
                          <Nav.Link
                            onClick={() => {
                              navigate("/inspectionstatus");
                              toggleOpen();
                            }}
                            style={{ color: "#000", padding: "10px" }}
                          >
                            <FontAwesomeIcon
                              icon={faCalendarCheck}
                              className="sideNavIcons"
                            />
                            Inspection Status
                          </Nav.Link>
                        )}
                      {(assetMaintenance?.r ||
                        deviceMaintenance?.r ||
                        facilityMaintenance?.r) && (
                          <Nav.Link
                            onClick={() => {
                              navigate("/maintenancestatus");
                              toggleOpen();
                            }}
                            style={{ color: "#000", padding: "10px" }}
                          >
                            <FontAwesomeIcon
                              icon={faWrench}
                              className="sideNavIcons"
                            />
                            Maintenance Status
                          </Nav.Link>
                        )}

                      {(accessOnDemand?.c ||
                        accessOnDemand?.r ||
                        accessOnDemand?.u) && (
                          <Nav.Link
                            onClick={() => {
                              navigate("/ondemandtatus");
                              toggleOpen();
                            }}
                            style={{ color: "#000", padding: "10px" }}
                          >
                            <FontAwesomeIcon
                              icon={faCalendarCheck}
                              className="sideNavIcons"
                            />
                            On-Demand Status
                          </Nav.Link>
                        )}
                      {/* {ticketAccess !== null && ticketAccess?.r ? (
                        <Nav.Link
                          onClick={() => {
                            navigate("/ticketstatus");
                            toggleOpen();
                          }}
                          style={{ color: "#000", padding: "10px" }}
                        >
                          <FontAwesomeIcon
                            icon={faTicket}
                            className="sideNavIcons"
                          />
                          Ticket Status
                        </Nav.Link>
                      ):<></>} */}
                      {/* <Nav.Link
                        onClick={() => {
                          navigate("/ticketstatus");
                          toggleOpen();
                        }}
                        style={{ color: "#000", padding: "10px" }}
                      >
                        <FontAwesomeIcon
                          icon={faTicket}
                          className="sideNavIcons"
                        />
                        Ticket Status
                      </Nav.Link> */}
                      {/* <Nav.Link
                        onClick={() => {
                          navigate("/report");
                          toggleOpen();
                        }}
                        style={{ color: "#000", padding: "10px" }}
                      >
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          className="sideNavIcons"
                        />
                        Report
                      </Nav.Link> */}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            ) : (
              <div></div>
            )}
          </div>

          <div>
            {assetVerification !== undefined &&
              assetVerification?.c === true && (
                <Nav.Link
                  onClick={() => {
                    navigate("/sampling");
                    toggleOpen();
                  }}
                  style={{ paddingLeft: "5px" }}
                  className="p-2"
                >
                  <FontAwesomeIcon icon={faShuffle} className="sideNavIcons" />
                  Asset Sampling
                </Nav.Link>
              )}
          </div>
          {/* .................. Reports ..................  */}
          <div>
            {(assetInspection?.c === true ||
              assetVerification?.c === true ||
              depreciationReport?.c === true ||
              verificationReport?.c === true) && (
                <Accordion>
                  <Accordion.Item
                    eventKey="2"
                    style={{
                      border: "none",
                      background: "none",
                      position: "relative",
                      paddingBottom: "15px",
                    }}
                  >
                    <Accordion.Header className="accordianHeader">
                      <FontAwesomeIcon
                        icon={faFileCircleCheck}
                        className="sideNavIcons"
                      />
                      Reports
                    </Accordion.Header>
                    <Accordion.Body>
                      {assetInspection !== undefined &&
                        assetInspection?.c === true ? (
                        <Nav.Link
                          onClick={() => {
                            navigate("/report");
                            toggleOpen();
                          }}
                          style={{ color: "#000", padding: "10px" }}
                        >
                          <FontAwesomeIcon
                            icon={faCalendarCheck}
                            className="sideNavIcons"
                          />
                          Usage Report
                        </Nav.Link>
                      ) : (
                        <div></div>
                      )}

                      {/* commented because access control is undefined - After fixing the access control, uncomment the below code */}
                      {/* {accessPlant !== undefined && accessPlant?.c === true ? ( */}
                      {verificationReport !== undefined &&
                        verificationReport?.c === true && (
                          <Nav.Link
                            onClick={() => {
                              navigate("/verification");
                              toggleOpen();
                            }}
                            style={{ color: "#000", padding: "10px" }}
                          >
                            <FontAwesomeIcon
                              icon={faQrcode}
                              className="sideNavIcons"
                            />
                            Verification Report
                          </Nav.Link>
                        )}
                      {depreciationReport !== undefined &&
                        depreciationReport?.c === true && (
                          <Nav.Link
                            onClick={() => {
                              navigate("/depreciationreport");
                              toggleOpen();
                            }}
                            style={{ paddingLeft: "5px" }}
                            className="p-2"
                            disabled={depreciationReport?.d}
                          >
                            <FontAwesomeIcon
                              icon={faFileLines}
                              className="sideNavIcons"
                            />
                            Depreciation Report
                          </Nav.Link>
                        )}
                      {/* <Nav.Link
                      onClick={() => {
                        navigate("/plant/verification/RFID");
                        toggleOpen();
                      }}
                      style={{ color: "#000", padding: "10px" }}
                    >
                      <FontAwesomeIcon
                        icon={faSquareRss}
                        className="sideNavIcons"
                      />
                      Plant Verification Report
                    </Nav.Link>*/}
                      {/* ) : (
                    <div></div>
                  )} */}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
          </div>

          {/* .................. Master ..................  */}
          {fixedAssetMaster && fixedAssetMaster?.c && (
            <Accordion>
              <Accordion.Item
                eventKey="3"
                style={{
                  border: "none",
                  background: "none",
                  position: "relative",
                  paddingBottom: "15px",
                }}
              >
                <Accordion.Header className="accordianHeader">
                  <FontAwesomeIcon
                    icon={faObjectGroup}
                    className="sideNavIcons"
                  />
                  Master
                </Accordion.Header>
                <Accordion.Body>
                  <Nav.Link
                    onClick={() => {
                      navigate("/location");
                      toggleOpen();
                    }}
                    className="p-2"
                    disabled={fixedAssetMaster?.d}
                  >
                    <FontAwesomeIcon
                      icon={faLocation}
                      className="sideNavIcons"
                    />
                    Location
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => {
                      navigate("/assetclass");
                      toggleOpen();
                    }}
                    className="p-2"
                    disabled={fixedAssetMaster?.d}
                  >
                    <FontAwesomeIcon
                      icon={faBraille}
                      className="sideNavIcons"
                    />
                    Asset Class
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => {
                      navigate("/costcenter");
                      toggleOpen();
                    }}
                    className="p-2"
                    disabled={fixedAssetMaster?.d}
                  >
                    <FontAwesomeIcon
                      icon={faBuilding}
                      className="sideNavIcons"
                    />
                    Cost Center
                  </Nav.Link>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}

          {/*....................... Audit Trail ...................... */}
          <div>
            {auditTrial !== undefined && auditTrial?.r === true && (
              <Nav.Link
                onClick={() => {
                  navigate("/auditTrail");
                  toggleOpen();
                }}
                style={{ paddingLeft: "5px" }}
                className="p-2"
                disabled={auditTrial?.d}
              >
                <FontAwesomeIcon
                  icon={faClipboardCheck}
                  className="sideNavIcons"
                />
                Audit Trail
              </Nav.Link>
            )}
          </div>
          <div>
            {reportMedo !== undefined && reportMedo?.r === true && (
              <Nav.Link
                onClick={() => {
                  navigate("/templateReport");
                  toggleOpen();
                }}
                style={{ paddingLeft: "5px" }}
                className="p-2"
              >
                <FontAwesomeIcon
                  icon={faFileArrowDown}
                  className="sideNavIcons"
                />
                Download Reports
              </Nav.Link>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Sidebar;
