import dotenv from "react-dotenv";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RequireAuth from "../src/utilities/RequireAuth";
import { useEffect } from "react";
import { connect } from "react-redux";
import { isAuthenticated } from "./redux/selectors/authSelector";
import "./globalStyle.css";
import "./iconsStyle.css";

//importing Login dependencies
import Login from "./modules/login/login";

//importing landing page component / home page dependencies
import Home from "./modules/home/home";
import Sidebar from "./components/sideNavBar/sideBarButton";
import LogoutButton from "./components/logOutButton";

//importing forgot password dependencies
import ForgetPass from "./modules/forgotPass/forgetPass";
import SetNewPass from "./modules/forgotPass/setNewPass";

//importing customer dependencies
import CustomerList from "./modules/customer/customerList";
import CustomerUpload from "./modules/customer/customerUpload";
import CustomerContact from "./modules/customer/customerContact";
import Customerheader from "./layouts/customer/customerHeader";
import CustomerUpdate from "./modules/customer/customerUpdate";

//importing site dependencies
import SiteList from "./modules/site/siteList";
import SiteUpload from "./modules/site/siteUpload";
import SiteUpdate from "./modules/site/siteUpdate";
import SiteContact from "./modules/site/siteContact";
import Siteheader from "./layouts/site/siteHeader";

// importing asset dependencies
import LayoutContainer from "./layouts/asset/layoutContainer";
import AssetList from "./modules/asset/assetList";
import AssetUpdate from "./modules/asset/assetUpdate";
import AssetUpload from "./modules/asset/assetUpload";
import AssetSingleView from "./modules/asset/assetView";
import Selectasset from "./modules/asset/selectAsset";

//importing device dependencies
import DeviceList from "./modules/device/deviceList";
import DeviceUpload from "./modules/device/deviceUpload";
import DeviceUpdate from "./modules/device/deviceUpdate";

//importing facility dependencies
import FacilityList from "./modules/facility/facilityList";
import FacilityUpload from "./modules/facility/facilityUpload";
import FacilityUpdate from "./modules/facility/facilityUpdate";

// importing maintenace & inspection dependencies
import CreateBulkSchedule from "./modules/schedules/bulkUpload";
import InspectionStatus from "./modules/workOrder/inspection/inspectionStatus";

// importing userrole dependencies
import Userrole from "./modules/userRole/userRoleList";
import UserCreation from "./modules/user/createUser";
import CreatePassword from "./modules/user/createPassword";
import UserList from "./modules/user/userList";
import Userheader from "./layouts/userRole/userHeader";

import DropdownQR from "./modules/QRcodeGenerator/dropdownQr";

import InspectionReport from "./modules/report/pdfPreview/inspectionReport";
import MaintenanceReport from "./modules/report/pdfPreview/maintenanceReport";
import UploadInspection from "./modules/schedules/inspection/uploadInspection";
// import UpdateInspection from "./modules/schedules/inspection/updateInspection";
import ScheduleUploadheader from "./layouts/schedule/scheUploadHeader";
// import UploadMaintenance from "./modules/schedules/maintenance/uploadMaintenance";
import InspectionList from "./modules/schedules/inspection/inspectionList";
import MaintenanceList from "./modules/schedules/maintenance/maintenanceList";
import ChooseType from "./modules/schedules/chooseTypes";
// import UpdateMaintenance from "./modules/schedules/maintenance/updateMaintainance";
import MaintenanceStatus from "./modules/workOrder/maintenance/maintStatus";
import UserDetail from "./components/userDetail/userDetail";
// import InspectionView from './modules/schedules/inspection/inspView';
// import MaintenanceView from './modules/schedules/maintenance/viewMaint/viewAppvl';
import AssetHistory from "./modules/asset/assetHistory";
import TatUpload from "./modules/ticket/TAT/tatUpload";
import TatList from "./modules/ticket/TAT/tatList";
import Genratepdf from "./modules/report/pdfPreview/genrateInsPdf";
import GenrateMainpdf from "./modules/report/pdfPreview/genrateMainpdf";
import Report from "./modules/report/report";
import Commonheader from "./layouts/common/commonHeader";
import Trend from "./modules/home/trend";
import { DashboardFms } from "./modules/FA/DashboardFms";
import { Register } from "./modules/FA/Register";
import { Cwip } from "./modules/FA/Cwip";
import Capitalised from "./modules/FA/Capitalised";
import Expensed from "./modules/FA/Expensed";
import Location from "./modules/FA/Location";
import LocationHeader from "./layouts/FA-layouts/LocationHeader";
import CreateLocation from "./modules/FA/createLocation";
import Depreciation from "./modules/FA/Depreciation";
import DepReport from "./modules/FA/DepReport";
import CapitalHeader from "./layouts/FA-layouts/CapitalHeader";
import AuditTrail from "./modules/auditTrail/auditTrail";

import TicketStatus from "./modules/workOrder/ticket/ticketStatus";
import GenrateTicketpdf from "./modules/report/pdfPreview/genrateTicketPdf";
import PhysicalVerify from "./modules/FA/PhysicalVerify";
import AssetClassHeader from "./layouts/FA-layouts/AssetClassHeader";
import CreateAssetClass from "./modules/FA/createAssetClass";
import AssetClass from "./modules/FA/ClassMaster";
import CostCenterHeader from "./layouts/FA-layouts/CostCenterHeader";
import CostMaster from "./modules/FA/CostMaster";
import CreateCostCenter from "./modules/FA/createCostCenter";
import TransferDispose from "./modules/FA/TransferDispose";
import Plant from "./modules/Plant/Plant";
import PlantDetails from "./modules/Plant/PlantDetails";
import PlantLink from "./modules/Plant/PlantLink";
import PlantVerification from "./modules/Plant/plantVerification";
import Sampling from "./modules/FA/sampling";
import PrivacyPolicy from "./modules/PrivacyPolicy/PrivacyPolicy";
import BIALMenuLink from "./modules/BIALMenu/BIALMenuLink";
import Restaurant from "./modules/BIALMenu/Restaurant";

// import Medo Report dependencies
import AllForm from "./modules/report/downloadReport-Hardcodes/allForm";
import TemplateReport from "./modules/report/templateReport";
import OnDemandStatus from "./modules/workOrder/on-demand/onDemandStatus";

//import Download Brochure
// import Download from './modules/company/company';
import Company from "./modules/company/company";
import RestaurantV1_0 from "./modules/BIALMenu/RestaurantV1_0";
import HouseRule from "./modules/BIALMenu/HouseRule";
import Menu from "./modules/BIALMenu/Menu";
import AssetPage from "./modules/Redirect/AssetDetails";
import Maintenancenew from "./modules/schedules/maintenance/maintenance";

// function to handle App.js
function App(props) {
  const { isAuthenticated } = props;
  // useEffect
  useEffect(() => {}, [isAuthenticated]);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/BIAL/:id" element={<Plant />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/BIAL/plant/:id" element={<PlantLink />}></Route>
          <Route
            path="/BIAL/plant/details/:id"
            element={<PlantDetails />}
          ></Route>
          <Route path="/basl/menus/:id" element={<BIALMenuLink />}></Route>
          <Route path="/Restaurant" element={<Restaurant />}></Route>
          <Route path="/basl" element={<RestaurantV1_0 />}></Route>
          <Route path="/basl/:id" element={<BIALMenuLink />}></Route>
          <Route path="/basl/house-rule" element={<HouseRule />}></Route>
          <Route path="/basl/menus" element={<Menu />}></Route>
          <Route path="/public" element={<AssetPage />}></Route>

          {/* forgot password routes */}
          <Route path="/forget" element={<ForgetPass />}></Route>
          <Route path="/forgetemail/:id" element={<SetNewPass />}></Route>
          <Route path="/newpass/:id" element={<CreatePassword />}></Route>
          {/* Landing / home page routes */}
          <Route element={<RequireAuth />}>
            <Route path="*" element={<Home />}></Route>
            <Route path="/home" element={<Home />}></Route>
            {/* <Route path="/home" element={<DashboardFms />}></Route> */}
            <Route path="/register" element={<Register />}></Route>
            <Route path="/cwip" element={<Cwip />}></Route>
            <Route path="/capitalised" element={<Capitalised />}></Route>
            <Route path="/expensed" element={<Expensed />}></Route>
            <Route path="/depreciation" element={<Depreciation />}></Route>
            <Route path="/depreciationreport" element={<DepReport />}></Route>
            {/* <Route path="/verification" element={<PhysicalVerify />}></Route > */}
            <Route
              path="/transfer-dispose"
              element={<TransferDispose />}
            ></Route>
            <Route path="/sideBar" element={<Sidebar />}></Route>
            <Route path="/userdetail" element={<UserDetail />}></Route>
            <Route path="/logout" element={<LogoutButton />}></Route>
            <Route path="/trend" element={<Trend />}></Route>
            <Route path="/qrcode-generator" element={<DropdownQR />}></Route>
            <Route path="/select/asset" element={<Selectasset />}></Route>
            <Route path="/sampling" element={<Sampling />}></Route>
            <Route
              path="/inspectionstatus"
              element={<InspectionStatus />}
            ></Route>
            <Route
              path="/maintenancestatus"
              element={<MaintenanceStatus />}
            ></Route>
            <Route path="/ondemandtatus" element={<OnDemandStatus />}></Route>
            <Route
              path="/inspection/report/:id"
              element={<Genratepdf />}
            ></Route>
            {/* <Route
              path="/inspection/report/:id"
              element={<InspectionReport />}
            ></Route> */}
            <Route
              path="/maintenance/report/:id"
              element={<GenrateMainpdf />}
            ></Route>
            <Route path="/ticketstatus" element={<TicketStatus />}></Route>
            <Route path="/ticket/report/:id" element={<GenrateTicketpdf />}></Route>
            <Route path="/tat-ticket/list" element={<TatList />}></Route>
            <Route
              path="/tat-ticket/upload/:id"
              element={<TatUpload />}
            ></Route>
          </Route>

          {/* customer header */}
          <Route element={<RequireAuth />}>
            <Route element={<Customerheader />}>
              <Route path="/customerlist" element={<CustomerList />}></Route>
              <Route
                path="/customerupload"
                element={<CustomerUpload />}
              ></Route>
              <Route path="/contact/:id" element={<CustomerContact />}></Route>
              <Route
                path="/customers/update/:id"
                element={<CustomerUpdate />}
              ></Route>
            </Route>
          </Route>

          {/* site header */}
          <Route element={<RequireAuth />}>
            <Route element={<Siteheader />}>
              <Route path="/sitelist" element={<SiteList />}></Route>
              <Route path="/siteupload" element={<SiteUpload />}></Route>
              <Route path="/sitecontact/:id" element={<SiteContact />}></Route>
              <Route path="/site/update/:id" element={<SiteUpdate />}></Route>
            </Route>
          </Route>

          <Route element={<RequireAuth />}>
            {/* Asset, Device & Facility Header routes */}
            <Route element={<LayoutContainer />}>
              {/* asset routes */}
              <Route path="/assetList" element={<AssetList />}></Route>
              <Route path="/asset/upload/:id" element={<AssetUpload />}></Route>
              <Route
                path="/assets/update/:id"
                element={<AssetUpdate />}
              ></Route>
              <Route
                path="/asset/view/:id"
                element={<AssetSingleView />}
              ></Route>

              <Route
                path="/inspection/maintenance-history/:id"
                element={<AssetHistory />}
              ></Route>

              {/* device routes */}
              <Route path="/devicelist" element={<DeviceList />}></Route>
              <Route
                path="/deviceupload/:id"
                element={<DeviceUpload />}
              ></Route>
              <Route
                path="/device/update/:id"
                element={<DeviceUpdate />}
              ></Route>

              {/* facility routes */}
              <Route
                path="/facility/upload/:id"
                element={<FacilityUpload />}
              ></Route>
              <Route
                path="/facility/update/:id"
                element={<FacilityUpdate />}
              ></Route>
              <Route path="/facilitylist" element={<FacilityList />}></Route>
            </Route>

            {/* Schedule */}
            <Route element={<RequireAuth />}>
              <Route path="/inspection/:id" element={<UploadInspection />} />
              <Route
                path="/inspection/view/:id"
                element={<UploadInspection />}
              />
              <Route
                path="/inspection/update/:id"
                element={<UploadInspection />}
              />
              {/* <Route path="/inspection/view/:id" element={<InspectionView />} /> */}

              {/* <Route
                path="/inspection/update/:id"
                element={<UpdateInspection />}
              /> */}
              <Route
                path="/maintainance/update/:id"
                element={<Maintenancenew />}
              />
              <Route
                path="/maintenance/view/:id"
                element={<Maintenancenew />}
              />
              <Route
                path="/create/bulkschedule/:id"
                element={<CreateBulkSchedule />}
              ></Route>
              {/* <Route
                path="/maintenance/:id"
                element={<UploadMaintenance />}
              ></Route> */}
              <Route
                path="/upload-inspection/maintainance/:id"
                element={<ChooseType />}
              ></Route>
            </Route>

            {/* Header part routing for Schedule */}
            <Route element={<RequireAuth />}>
              <Route element={<ScheduleUploadheader />}>
                <Route
                  path="/inspectionlist"
                  element={<InspectionList />}
                ></Route>
                <Route
                  path="/maintenancelist"
                  element={<MaintenanceList />}
                ></Route>
              </Route>
            </Route>

            {/* Header part routing for Report */}
            <Route element={<RequireAuth />}>
              <Route element={<Commonheader />}>
                <Route path="/report" element={<Report />}></Route>
                <Route path="/auditTrail" element={<AuditTrail />}></Route>
                <Route
                  path="/verification"
                  element={<PlantVerification />}
                ></Route>
              </Route>
            </Route>

            {/* User Role Management */}
            <Route element={<RequireAuth />}>
              <Route element={<Userheader />}>
                <Route path="/user/role" element={<Userrole />}></Route>
                <Route path="/user" element={<UserCreation />}></Route>
                <Route path="/user/list" element={<UserList />}></Route>
              </Route>
            </Route>
            <Route element={<RequireAuth />}>
              <Route element={<LocationHeader />}>
                <Route path="/location" element={<Location />}></Route>
                <Route path="/addlocation" element={<CreateLocation />}></Route>
              </Route>
            </Route>
            <Route element={<RequireAuth />}>
              <Route element={<CostCenterHeader />}>
                <Route path="/costcenter" element={<CostMaster />}></Route>
                <Route
                  path="/addcostcenter"
                  element={<CreateCostCenter />}
                ></Route>
              </Route>
            </Route>
            <Route element={<RequireAuth />}>
              <Route element={<AssetClassHeader />}>
                <Route path="/assetclass" element={<AssetClass />}></Route>
                <Route
                  path="/addassetclass"
                  element={<CreateAssetClass />}
                ></Route>
              </Route>
              {/* <Route element={<CapitalHeader />}> */}
              {/* <Route path="/capitalised" element={<Capitalised />}></Route> */}
              {/* </Route> */}
            </Route>
            <Route element={<RequireAuth />}>
              <Route path="/reportform/:id" element={<AllForm />}></Route>
              <Route
                path="/templateReport"
                element={<TemplateReport />}
              ></Route>
            </Route>
          </Route>
          <Route path="/company" element={<Company />}></Route>
        </Routes>
      </Router>
    </>
  );
}

// function to map state to the props
const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};
export default connect(mapStateToProps)(App);
